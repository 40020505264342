import { Anchor, AnchorProps } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { ensureTrailingSlash, normalizeErrorString } from '@iehr/core';
import { ClientApplication, Encounter, Patient, Reference, SmartAppLaunch } from '@iehr/fhirtypes';
import { useIEHR } from '@iehr/react-hooks';
import { ReactNode } from 'react';

export interface SmartAppLaunchLinkProps extends AnchorProps {
  readonly client: ClientApplication;
  readonly patient?: Reference<Patient>;
  readonly encounter?: Reference<Encounter>;
  readonly children?: ReactNode;
}

export function SmartAppLaunchLink(props: SmartAppLaunchLinkProps): JSX.Element | null {
  const iehr = useIEHR();
  const { client, patient, encounter, children, ...rest } = props;

  function launchApp(): void {
    iehr
      .createResource<SmartAppLaunch>({
        resourceType: 'SmartAppLaunch',
        patient,
        encounter,
      })
      .then((result) => {
        const url = new URL(client.launchUri as string);
        url.searchParams.set('iss', ensureTrailingSlash(iehr.fhirUrl().toString()));
        url.searchParams.set('launch', result.id as string);
        window.location.assign(url.toString());
      })
      .catch((err) => showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false }));
  }

  return (
    <Anchor onClick={() => launchApp()} {...rest}>
      {children}
    </Anchor>
  );
}
